<template>
  <kurcc-data-table-page-template :key="key"
                                  :data-table-title="getDataTableTitle"
                                  :default-item="defaultItem"
                                  :delete-dialog-title="getDeleteDialogTitle"
                                  :get-all-action-additional-params="prepareAdditionalParams"
                                  :headers="headers"
                                  :show-delete-action="showDeleteAction"
                                  :show-update-action="showUpdateAction"
                                  :toolbar-button-dialog-title="$vuetify.lang.t('$vuetify.pages.staff.addMember')"
                                  :toolbar-button-text="$vuetify.lang.t('$vuetify.pages.staff.addMember')"
                                  :update-dialog-title="$vuetify.lang.t('$vuetify.pages.staff.editStaff')"
                                  actions-suffix="Staff"
                                  show-select>
    <template v-slot:dialog="{item}">
      <kurcc-group-member-dialog v-if="groupData" :item="item"/>
      <kurcc-staff-dialog v-else :item="item"/>
    </template>
  </kurcc-data-table-page-template>
</template>

<script>
import Staff from "@/modules/staff/models/staff";

export default {
  name: "KurccStaffPage",
  props: ['group_id', 'group'],
  components: {
    KurccDataTablePageTemplate: () => import('@/modules/app/templates/KurccDataTablePageTemplate'),
    KurccStaffDialog: () => import('@/modules/staff/components/KurccStaffDialog'),
    KurccGroupMemberDialog: () => import('@/modules/groups/components/KurccGroupMemberDialog')
  },
  data() {
    return {
      headers: [],
      defaultItem: new Staff(),
      key: this.$dayjs().toISOString(),
      groupData: undefined
    }
  },
  computed: {
    prepareAdditionalParams() {
      let params = []
      if (this.isGroupMemberPage) params.push(`group_id=${this.group_id}`)
      return params
    },
    isGroupMemberPage() {
      return this.group_id
    },
    getDataTableTitle() {
      if (this.groupData)
        return `${this.$vuetify.lang.t('$vuetify.pages.staff.staffList')} (${this.$vuetify.lang.t('$vuetify.general.group')}: ${this.groupData.name})`
      else return "" // this.$vuetify.lang.t('$vuetify.pages.staff.staffList')
    },
    getDeleteDialogTitle() {
      if (this.groupData)
        return this.$vuetify.lang.t('$vuetify.pages.staff.deleteFromGroupText')
      else return this.$vuetify.lang.t('$vuetify.pages.staff.deleteStaffText')
    },
    showUpdateAction() {
      return !this.isGroupMemberPage
    },
    showDeleteAction() {
      return !!this.isGroupMemberPage
    }
  },
  watch: {
    $route() {
      this.key = this.$dayjs().toISOString()
      this.initialize()
    }
  },
  methods: {
    resetHeaders() {
      this.headers = [
        {
          text: this.$vuetify.lang.t('$vuetify.pages.staff.dataTableHeaders.firstName'),
          align: 'start',
          value: 'first_name',
        },
        {text: this.$vuetify.lang.t('$vuetify.pages.staff.dataTableHeaders.lastName'), value: 'last_name'},
        {text: this.$vuetify.lang.t('$vuetify.pages.staff.dataTableHeaders.phoneNumber'), value: 'phone_number'},
        {text: this.$vuetify.lang.t('$vuetify.pages.staff.dataTableHeaders.email'), value: 'email'},
        {
          text: this.$vuetify.lang.t('$vuetify.pages.staff.dataTableHeaders.isAdmin'),
          value: 'ids_of_groups_staff_is_admin_in',
          sortable: false
        },
        {text: this.$vuetify.lang.t('$vuetify.general.createdAt'), value: 'created_at'},
        {text: this.$vuetify.lang.t('$vuetify.pages.staff.dataTableHeaders.staffId'), value: 'staff_id'},
        {text: this.$vuetify.lang.t('$vuetify.pages.staff.dataTableHeaders.role'), value: 'roles_names', sortable: false},
        {text: this.$vuetify.lang.t('$vuetify.general.status'), value: 'enabled'},
        {text: this.$vuetify.lang.t('$vuetify.general.actions'), value: 'actions', sortable: false},
      ]
    },
    fetchGroup() {
      this.$store.dispatch('getGroup', {id: this.group_id})
          .then(res => {
            this.groupData = res
          })
    },
    fetchCurrentPageData() {
      if (this.isGroupMemberPage) {
        this.setGroupData()
      } else {
        this.groupData = undefined
        this.removeIsAdminColumnHeader()
      }
    },
    setGroupData() {
      if (this.group)
        this.groupData = this.group
      else this.fetchGroup()
    },
    removeIsAdminColumnHeader() {
      let is_admin_index = this.$lodash.findIndex(this.headers, item => item.value === 'ids_of_groups_staff_is_admin_in')
      this.headers.splice(is_admin_index, 1)
    },
    initialize() {
      this.resetHeaders()
      this.fetchCurrentPageData()
    }
  },
  created() {
    this.initialize()
  }
}
</script>
